import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "./components/Pages/Home";
import HomeTwo from "./components/Pages/HomeTwo";
import HomeThree from "./components/Pages/HomeThree";
import HomeFour from "./components/Pages/HomeFour";
import HomeFive from "./components/Pages/HomeFive";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact={true} path={"/"} component={Home} />
            <Route path={"/HomeTwo"} component={HomeTwo} />
            <Route path={"/HomeThree"} component={HomeThree} />
            <Route path={"/HomeFour"} component={HomeFour} />
            <Route path={"/HomeFive"} component={HomeFive} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
